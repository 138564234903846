<template>
	<div class="Invitation">
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="邀请函列表"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11" 
		:border="false"
		placeholder
		>
	</van-nav-bar>
		
		
		<div class="Invitation_fixed">
			<div class="Invitation_top">
				<div class="Invitation_tab" :class="{'active':form.type_id == 1}" @click="onactive(1)">
					<img src="@/assets/invitation/icon_shijai.png" mode="">
					<div class="">
						试驾邀请函
					</div>
				</div>
				<div class="Invitation_tab" :class="{'active':form.type_id == 4}" @click="onactive(4)">
					<img src="@/assets/invitation/thank_letter.png" mode="">
					<div class="">
						邀请函
					</div>
				</div>
				<div class="Invitation_tab" :class="{'active':form.type_id == 5}" @click="onactive(5)">
					<img src="@/assets/invitation/invitation_letter.png" mode="">
					<div class="">
						致谢函
					</div>
				</div>
			</div>
		</div>
		<!-- 站位 -->
		<div class="zhanwei">
			
		</div>
		<div class="inviters">
			<van-list
				v-model="loading"
				:finished="finished"
				finished-text="没有更多了"
				@load="onLoad"
			>
				<div class="inviter" v-for="item in invitationLetterList" :key="item.id" @click="handInviter(item)">
					<div class="inviter__left">
						<div v-if="form.type_id == 1"  class="inviter__user">
							<span>{{item.customer_name}}</span><span>{{item.customer_mobile}}</span>
						</div>
						<div v-if="form.type_id != 1"  class="inviter__user">
							<span>{{item.formwork_name}}</span>
						</div>
						<div v-if="form.type_id == 1" class="inviter__car">
							凯迪拉克 {{item.model_name}}
						</div>
						<div v-if="form.type_id != 1" class="inviter__car">
							{{item.username}}
						</div>
						<div v-if="form.type_id == 1" class="inviter__time">
							{{item.appointment_time}}
							<div v-if="item.if_check == 1" class="inviter__time__botton">
								已确认
							</div>
						</div>
						<div v-if="form.type_id != 1" class="inviter__time">
							{{item.createtime}}
						</div>
					</div>
					<div class="inviter__right">
						<img src="@/assets/common/icon_right.png">
					</div>
				</div>
			</van-list>
		</div>
	
	</div>
</template>

<script>
import { getDateTime2, getDateTime3 } from '@/utils/utils.js'
	export default {
		components: {
			
		},
		data() {
			return {
				type: 'invitation_letter',
				invitationLetterList: [],
				form:{
					type_id:1,
					page:1,
					number:30,
				},
				status:'more',
				total:'',
				loading: false,
				finished: false,
			};
		},
		mounted() {
			const key = localStorage.getItem('formactive')
			if(key != null && key != ''){
				this.form.type_id = key
			}
			this.getInvitationList()
		},
		methods: {
			isToday(str){
			let d = new Date(parseInt(str *1000)).setHours(0, 0, 0, 0);
			
			let today = new Date().setHours(0, 0, 0, 0);
			let obj = {
				0: '今日',
			};
			return obj[d - today] || false;
		},
				async onLoad() {
					if(this.form.page == 1) return this.loading = false;
				// 异步更新数据
				// setTimeout 仅做示例，真实场景中一般为 ajax 请求
					const {
						data: res
					} = await this.$http.post('/api/scsj/get_store_invitation_list_1_7',this.form)
					if (res.code == 1) {
						this.form.page++
						this.total = res.data.total
						if(this.form.type_id == 1){
						if(res.data.res?.length != 0){
							res.data.res = res.data.res.map(v=>{
								const ss = this.isToday(v.appointment_time)
								if(ss){
									v.appointment_time = getDateTime3(v.appointment_time)
									v.appointment_time = v.appointment_time.slice(v.appointment_time.indexOf(' '))
									v.appointment_time = ss + ' ' + v.appointment_time
								}else{
									v.appointment_time = getDateTime3(v.appointment_time)
								}
								return v
							})
						}	
					}else{
						res.data.res = res.data.res.map(v=>{
								const ss = this.isToday(v.createtime)
								if(ss){
									v.createtime = getDateTime3(v.createtime)
									v.createtime = v.createtime.slice(v.createtime.indexOf(' '))
									v.createtime = ss + ' ' + v.createtime
								}else{
									v.createtime = getDateTime3(v.createtime)
								}
								return v
							})
					}
						this.invitationLetterList = this.invitationLetterList.concat(res.data.res)
						this.loading = false;
						if (this.invitationLetterList.length >= this.total) {
							this.finished = true;
						}
					}
			},
			getDateTime4(timestamp) {
				const date = new Date(timestamp * 1000)
				const M = (date.getMonth() + 1 < 10 ? (date.getMonth() + 1) : date.getMonth() + 1) + '月'
				const D = (date.getDate() < 10 ? date.getDate() : date.getDate()) + '日 '
				const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
				const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
				return M + D + h + m
			},
			// 切换列表
			onactive(e){
				localStorage.setItem('formactive',e)
				this.form.page = 1
				this.invitationLetterList = []
				this.form.type_id = e
				this.finished = false
				this.getInvitationList()
			},
			// 获取数据列表
			async getInvitationList() {
				const {
					data: res
				} = await this.$http.post('/api/scsj/get_store_invitation_list_1_7',this.form)
				if (res.code == 1) {
					this.total = res.data.total
					this.form.page++
					if(this.form.type_id == 1){
						if(res.data.res?.length != 0){
							res.data.res = res.data.res.map(v=>{
								const ss = this.isToday(v.appointment_time)
								if(ss){
									v.appointment_time = getDateTime3(v.appointment_time)
									v.appointment_time = v.appointment_time.slice(v.appointment_time.indexOf(' '))
									v.appointment_time = ss + ' ' + v.appointment_time
								}else{
									v.appointment_time = getDateTime3(v.appointment_time)
								}
								return v
							})
						}	
					}else{
						res.data.res = res.data.res.map(v=>{
								const ss = this.isToday(v.createtime)
								if(ss){
									v.createtime = getDateTime3(v.createtime)
									v.createtime = v.createtime.slice(v.createtime.indexOf(' '))
									v.createtime = ss + ' ' + v.createtime
								}else{
									v.createtime = getDateTime3(v.createtime)
								}
								return v
							})
					}
					this.invitationLetterList = this.invitationLetterList.concat(res.data.res)
				}
			},
			// 试驾邀请
			handInviter(e) {
				if(this.form.type_id == 1){
					this.$router.push({
						path: `/trial-invitation?id=${e.id}`
					})
				}else if(this.form.type_id == 4){
					
						this.$router.push({
							path: `/trial-invitation/invitation-info?id=${e.id}`
						})
					
				}else{
					if(e.formwork_name == '提车恭喜函'){
						this.$router.push({
							path: `/trial-invitation/enjoy-village-info?id=${e.id}`
						})
					}else{
					this.$router.push({
						path: `/trial-invitation/check-outinfo?id=${e.id}`
					})
				}
			}
				
			},
		}
	}
</script>

<style lang="scss" scoped>
.Invitation_fixed{
	position: fixed;
	width: 100%;
	box-sizing: border-box;
	background-color: rgba(25, 25, 25, 1);
}
.Invitation_top{
	padding: 40px;

	display: flex;
	justify-content: space-between;
	.Invitation_tab{
		border: 1px solid #303030;
		width: 200px;
		box-sizing: border-box;
		height: 208px;
		background: #303030;
		text-align: center;
		padding: 40px 0;
		img{
			width: 80px;
			height: 80px;
		}
		div{
			margin-top: 20px;
			text-align: center;
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			font-weight: 400;
			color: #FAFAFA;
		}
	}
}
.inviters{
	padding: 0 40px 40px;
}
.inviter{
		margin-bottom: 30px;
		padding: 20px 32px;
		width: 100%;
		box-sizing: border-box;
		background: #373C46;
		border-radius: 12px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		&__user{
			font-size: 36px;
			font-family: HYQiHeiY3-45;
			color: #9B9B9B;
			line-height: 36px;
			span:first-child{
				color: #fff;
				margin-right: 10px;
			}
		}
		&__car{
			margin-top: 15px;
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			color: #fff;
			line-height: 28px;
		}
		&__time{
			display: flex;
			align-items: center;
			margin-top: 15px;
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			color: #fff;
			line-height: 28px;
			&__botton{
				margin-left: 36px;
				width: 104px;
				height: 48px;
				background: #C8002B;
				border-radius: 8px;
				line-height: 48px;
				text-align: center;
				font-size: 26px;
				font-family: HYQiHeiY3-45;
				font-weight: normal;
				color: #ECECEC;
			}
		}
		&__right{
			img{
				width: 40px;
				height: 32px;
			}
		}
	}

.active{
	border: 1px solid #FA0037 !important;
}

.zhanwei{
	height: 330px;
}
</style>
